<template>
  <div class="loadingData" v-if="state.isLoading">
    <IconsWorker icon="loading" :width="40"/>
  </div>
  <div class="editFPageContainer" v-else>
    <div class="pageTitle" v-show="getTitle() !== ''">{{getTitle()}}</div>
    <textarea v-model="state.content" />
    <DuButton text="حفظ" class="saveButton" @click="saveData()" :disabled="state.isSavingData"/>
  </div>
</template>

<script>
import DuButton from "../../components/DuButton";
import {onMounted, reactive} from "vue";
import {useRouter, useRoute} from "vue-router";
import AjaxWorker from "../../helpers/AjaxWorker";
import FixedNotification from "../../components/plugins/fixedNotifications/FixedNotification";
import IconsWorker from "../../components/IconsWorker";

export default {
  name: "EditFixedPage",
  components: {IconsWorker, DuButton},
  setup() {
    const state = reactive({
      content: '',
      isLoading: true,
      isSavingData: false,
    });

    const route = useRoute();
    const getTitle = () => {
      return route.query.title === undefined ? '' : route.query.title;
    }

    const saveData = () => {
      state.isSavingData = true;
      const newSettings = {};
      newSettings[route.params.page] = state.content;

      new AjaxWorker().request("/transactions.php?action=saveSiteSettings", {
        settings: JSON.stringify(newSettings),
      }).then((res) => {
        state.isSavingData = false;
        if(res === "NOINTERNETCONNECTION" || !res.state) {
          FixedNotification.create({
            decription: 'حدث خطاء غير معلوم',
            icon: "errorF",
            color: 'red',
            hideAfter: 3000
          });
        } else {
          FixedNotification.create({
            decription: 'تم تحديث المحتوى',
            icon: "settingF",
            color: 'green',
            hideAfter: 3000
          });
        }
      });
    }


    const getData = () => {
      const keys = [route.params.page];
      state.isLoading = true;
      new AjaxWorker().request("/transactions.php?action=getSettings&keys=" + keys.join(',')).then((res) => {
        if(res === "NOINTERNETCONNECTION" || !res.state) {
          setTimeout(() => {
            getData();
          }, 3000);
        } else {
          state.content = res.data[route.params.page];
          state.isLoading = false;
        }
      });
    }

    onMounted(() => {
      getData();
    });

    return {
      state,
      getTitle,
      saveData,
    }
  }
}
</script>

<style scoped>

.editFPageContainer{
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  row-gap: 20px;
  text-align: start;
  gap: 10px;
}

.editFPageContainer a{
  color: inherit;
}

.editFPageContainer .pageTitle{
  width: available;
  font-size: 22px;
  font-weight: bold;
  color: #999;
}

.editFPageContainer textarea{
  min-height: 400px;
  border-radius: 10px;
  border: 1px solid #ccc;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;
}

.editFPageContainer .saveButton{
  place-self: end;
}

.loadingData{
  display: flex;
  place-items: center;
  place-content: center;
  padding: 200px 0 ;
}

</style>